<template>
  <v-card class="pa-0">
    <v-toolbar min-width="100%" dark color="primary">
      <v-card-title>Attendance Timing </v-card-title>

      <v-dialog
        v-model="dialog"
        persistent
        max-width="50%"
        style="align: right"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" class="ml-auto" v-bind="attrs" v-on="on">
            Add Attendance Time
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5"> New Attendance </v-card-title>

          <v-card-text>
            <v-form v-on:submit.prevent="save" ref="form">
              <v-autocomplete
                :items="rooms"
                item-text="room_name"
                item-value="room_id"
                v-model="room"
                label="Class Room *"
                :rules="$requiredRules"
                multiple
              />

              <v-row style="background-color: azure">
                <v-row>
                  <v-col>
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :rules="$requiredRules"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="start"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="start"
                          label="Start Time *"
                          prepend-inner-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="$requiredRules"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu1"
                        ampm-in-title
                        format="ampm"
                        color="red lighten-1"
                        v-model="start_time"
                        :max="end"
                        @click:minute="$refs.menu1.save(start_time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :rules="$requiredRules"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="end_time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="end_time"
                          label="End Time *"
                          prepend-inner-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="$requiredRules"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu2"
                        ampm-in-title
                        format="ampm"
                        color="red lighten-1"
                        v-model="end_time"
                        :max="end"
                        @click:minute="$refs.menu2.save(end_time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete
                    :items="graceTime"
                    item-text="time"
                    item-value="id"
                    v-model="grace_time"
                    label="Grace Time *"
                    :rules="$requiredRules"
                 
                  />
                </v-col>
                <v-col>
                  <v-autocomplete
                    :items="attendtype"
                    item-text="name"
                    item-value="id"
                    v-model="attend_type"
                    label="Checkin/Checkoout *"
                    :rules="$requiredRules"
                    multiple
                  />
                </v-col>
                <v-col
                  ><v-select
                    multiple
                    chips
                    v-model="days"
                    label="Select day(s) *"
                    :items="day_list"
                    item-text="name"
                    item-value="id"
                    :rules="$requiredRules"
                  ></v-select
                ></v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="greens darken-1" type="submit" @click="save">
              Save
            </v-btn>
            <v-btn color=" darken-1" @click="cancel_dialog"> cancel </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <!-- <v-autocomplete
      :items="rooms"
      item-text="name"
      item-value="id"
      v-model="room"
      label="Class Room *"
      :rules="$requiredRules"
      multiple
      :filter="roomFilter"
    /> -->
    <DataTableSSR
      apiEndPoint="/class_attandance/"
      :headers="headers"
      :instantLoad="true"
      :queryParams="{
        room: room,
      }"
         :key="refreshKey"
    >
      <template #[`item.show`]="{ item }">
        <v-row>
          <v-autocomplete
            :items="rooms"
            item-text="room_name"
            item-value="room_id"
            v-model="item.rooms"
            flat
            append-icon=""
            multiple
            hide-details
            class="no-underline"
            hide-selected
            disabled
          ></v-autocomplete>
        </v-row>
      </template>
      <template #[`item.day`]="{ item }">
        <v-autocomplete
          :items="day_list"
          item-text="name"
          item-value="id"
          v-model="item.days"
          flat
          class="no-underline"
          append-icon=""
          multiple
          hide-selected
          disabled
        />
      </template>
      <template #[`item.start_time`]="{ item }">
        {{ formatTime(item.start_time) }}
      </template>
      <template #[`item.end_time`]="{ item }">
        {{ formatTime(item.end_time) }}
      </template>

      <template #[`item.action`]="{ item }">
        <v-dialog max-width="700px" v-model="item.dialogNewFee">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              v-bind="attrs"
              color="primary"
              class="ma-4"
              dark
              v-on="on"
              outlined
              >Edit</v-btn
            >
          </template>
          <attend-update
            :item="item"
            @closeDialog="item.dialogNewFee = false"
            :loading="loading"
          ></attend-update>
        </v-dialog>
      </template>
    </DataTableSSR>
  </v-card>
</template>

<script>
import DataTableSSR from "../../../components/global/DataTableSSR.vue";
import AttendUpdate from "./AttendUpdate.vue";
export default {
  props: ["item"],
  components: { DataTableSSR, AttendUpdate },
  data() {
    return {
      dialog: false,
      form: "",
      rooms: [],
      menu1: false,
      menu2: false,
      menu3: false,
      attend_type: "",
      days: "",
      dialogNewFee: false,
      loading: false,
      room: "",
      refreshKey: 0,
      start_time: null,
      grace_time: "",
      end_time: null,
      loading: false,
      day_list: [],
      attandance: [],
      attendtype: [],
      graceTime: [],
      headers: [
        {
          text: "Class",
          value: "show",
          // align: "center",
        },
        {
          text: "Day",
          value: "day_names",
          // align: "center",
        },
        {
          text: "Start Time",
          value: "start_time",
          align: "center",
        },
        {
          text: "End Time",
          value: "end_time",
          align: "center",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
        },
      ],
    };
  },
  mounted() {
    this.loadGraceTime();
    this.loadAttendanceType();
    this.loadOwnedRooms();
    this.loadDays();
    this.loadAttend();
  },
  // created(){
  //   this.loadGraceTime();
  //   this.loadAttendanceType();
  // },

  methods: {
    loadAttend() {
      return this.$api
        .get(`/class_attandance/${this.item.id}`)
        .then((response) => {
          this.attandance = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        const startTime = parseInt(this.start_time);
        const endTime = parseInt(this.end_time);
        if (startTime < endTime) {
          const data = {
            days: this.days,
            start_time: this.start_time,
            end_time: this.end_time,
            rooms: this.room,
            grace_time: this.grace_time,
            attend_type: this.attend_type,
          };
          return this.$api
            .post("/class_attandance/", data)
            .then((this.dialog = false))
            .finally(() => {
              this.refreshKey += 1; 

            });
        } else {
          alert("Your Start Time should be less than End Time!");
        }
      }
    },
    
    roomFilter(search, item) {
      return item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
    loadOwnedRooms() {
      this.$api.get("/class_rooms/").then((res) => {
        this.rooms = res.data.results;
      });
    },
    loadDays() {
      this.$api.get("/attend-days/").then((res) => {
        this.day_list = res.data.results;
      });
    },
    loadAttendanceType() {
      this.$api.get("/attendance_type/").then((res) => {
        this.attendtype = res.data.results;
      });
    },
    loadGraceTime() {
      this.$api.get("/grace_time/").then((res) => {
        this.graceTime = res.data.results;
      });
    },
    cancel_dialog() {
      this.dialog = false;
    },
    formatTime(timeString) {
      if (!timeString) {
        return '';
      }

      const [hours, minutes] = timeString.split(':');
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const period = hours < 12 ? 'AM' : 'PM';

      return `${formattedHours}:${minutes} ${period}`;
    },
  },
};
</script>

<style>
.no-underline .v-input__slot::before {
  border-style: none !important;
}

.v-autocomplete__input::after {
  display: none;
}
</style>
